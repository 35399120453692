:root {
    --font-color: #ffffff;
}

@media (min-width: 1400px) {

    .container-xxl,
    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 920px;
    }
}

.fontColor1 {
    color: var(--font-color) !important;
}

.font-15 {
    font-size: 15px;
}

.font-17 {
    font-size: 17px;
    font-weight: 700;
}

.cursor-pointer {
    cursor: pointer;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background: #B1CED4;
    color: #000;
    border-radius: 50px;
}

.icon-nodatafound {
    fill: var(--font-color);
}

.nav-pills .nav-link {
    background: #D9D9D9;
    color: #000;
    border-radius: 50px;
    height: 31px;
    line-height: 14px;
    font-size: 14px;
    padding-left: 20px;
    padding-right: 20px;
}

.cardbg {
    background-color: #EAF0F0;
    border-radius: 13px;
    padding: 10px;
}

.totalbg {
    background: #FAFAFA;
    /* Actions extras/unactive text-color */
    border: 1px dashed #A9A9A9;
    border-radius: 14px 14px 0px 0px;
}

.btn-primary {
    background-color: #B1CED4;
    border: none;
    color: #000;
}

.btn-primary:hover {
    background-color: #83d4e5;
    border: none;
    color: #000;
}

.otpnumber {
    input {
        height: 44px;
        text-align: center;
        font-size: 22px;
    }
}

.tabspills .nav-pills .nav-link {
    border-radius: 5px;
    height: 41px;
    text-transform: uppercase;
    font-size: 15px;
}

.btn:focus-visible {
    background-color: #000;
    border-color: #000;
    box-shadow: none !important;
}

.btn:active {
    background-color: #000 !important;
    border-color: #000 !important;
    box-shadow: none !important;
}

.badge1 {
    background-color: #FE492E;
    border-radius: 50px;
    position: absolute;
    right: -6px;
    width: 25px;
    text-align: center;
    color: #fff;
}

a {
    text-decoration: none;
}

.gap-2.nav.nav-pills {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: stretch;
    align-items: stretch;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 5px;
    -webkit-overflow-scrolling: touch;
}

body {
    background-color: #000;
    background-image: url('../../assets/img/mainbg.jpg');
    min-height: 100vh;
    background-size: cover;
    background-position: center;
}

.page-container {
    min-height: calc(100vh - 155px);
}

.cardborder {
    position: relative;
}

.cardborder::before {
    content: "";
    background-color: #0000007a;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 0;
    bottom: 0;
    border-radius: 7px;
}

.cardborder {
    span {
        z-index: 9;
    }
}

.image-gallery-icon.image-gallery-right-nav {
    display: none;
}

.image-gallery-icon.image-gallery-left-nav {
    display: none;
}

.image-gallery-icon.image-gallery-fullscreen-button {
    display: none;
}

.image-gallery-icon.image-gallery-play-button {
    display: none;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
    outline: none;
    border: 4px solid #84d4e4;
}

.carousel-control-prev,
.carousel-control-next {
    width: 7%;
}

.gridheading p {
    font-size: 12px;
    padding-top: 5px;
    margin-bottom: 0px !important;
}

.visabilityhidden {
    visibility: hidden;
}

.carousel-indicators {
    visibility: hidden;
}

.nav-pills.nav-fill {
    border-bottom: 2px solid #EEF0F1;
    gap: 0 !important;
}



.nav-pills .nav-link.active {
    border-bottom: 3px solid #b1ced4;
    background-color: #fff;
    border-radius: 0px;
    width: max-content;
}

.nav-pills .nav-link {
    border-bottom: 3px solid #EEF0F1;
    background-color: #fff;
    border-radius: 0px;
    color: #A9A9A9;
    width: max-content;
}

.addressbg {
    background: #F4F4F4;
    border-radius: 5px;

    .active {
        border: 3px solid #B1CED4;
    }
}

.addressbg.active {
    background: #F4F4F4;
    border-radius: 5px;
    border: 3px solid #B1CED4;

}

.overflow-y {
    overflow-y: scroll;
}

@media only screen and (max-width: 600px) {
    .homebutton {
        position: absolute;
        top: 30px;
        left: 40px;
    }
}

.object-fit {
    object-fit: cover;
}

button .loader {
    width: 20px;
    height: 20px;
    border: 3px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    margin-right: 14px;
}

.component-loader {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    z-index: 2;
    left: 50%;
    margin-left: -30px;
    top: 50%;

    .loader-icon {
        width: 120px;
        height: 120px;
    }

    .loader {
        width: 80px;
        height: 80px;
        background-image: url('../../assets/images/webmaxyloader.svg');
        background-size: contain;

    }
}

.container {
    height: calc(100vh - 161px);
}

.mobilenumber input {
    width: 100% !important;
}

.spinner-border {
    --bs-spinner-width: 4rem !important;
    --bs-spinner-height: 4rem !important;
}

.no-data-found {
    width: 100%;
    height: 55vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    border-radius: 6px;
}

@media only screen and (max-width: 600px) {
    .cardborder span {
        font-size: 14px !important;
    }
}

.menucard1 {
    height: 170px;
    object-fit: cover;
}

.alink:hover {
    background-color: rgba(0, 0, 0, .03);
    border-bottom: 1px solid rgba(0, 0, 0, .125);
}

.loader-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

body {
    overflow-x: hidden;
}

.react-tel-input .country-list .country-name {
    margin-right: 6px;
    color: black !important;
}